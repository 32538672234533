<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useUIStore } from "~~/stores/ui";

const { drawer } = storeToRefs(useUIStore());
const { check, dashboard } = usePermissions();

const items = ref([
  { title: "Dispatch", value: "", to: "/dispatch", icon: "mdi-text-box-edit" },
]);

const rail = ref(true);

const toggleRail = () => {
  // rail.value = !rail.value;
  // console.log(`[toggleRail]`, rail.value);
};

await check();
// cl("AppDrawer", `dashboard length: ${JSON.stringify(dashboard.value.length)}`);
</script>

<template>
  <div>
    <v-card>
      <v-navigation-drawer v-model="drawer"
                           :rail="rail"
                           permanent
                           @click="toggleRail">
        <v-list density="compact"
                nav>


          <v-list-item v-for="item in dashboard"
                       :key="item.title"
                       :prepend-icon="item.icon"
                       :title="item.title"
                       :to="item.to">
            <v-tooltip activator="parent"
                       location="end">{{ item.title }}</v-tooltip>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <v-list density="compact">
            <v-list-item nav
                         prepend-icon="mdi-message-question"
                         title="FAQ"
                         to="/docs">
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>
    </v-card>
  </div>
</template>
