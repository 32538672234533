import { storeToRefs } from "pinia";
import type { IPermission } from "~/types";
import { dashboardList } from "~/types/ui";

interface IDashboardItem {
  title: string;
  value: string;
  to: string;
  icon: string;
}

export const usePermissions = () => {
  const { isAppDrawerVisible } = storeToRefs(useUIStore());
  const permissions = ref<IPermission[] | null>();
  const dashboard = ref<IDashboardItem>();
  const { data: sessionData } = useAuth();
  const allPermissions = useState("permissions", () => permissions.value);

  const fetchPermissions = async () => {
    const { data, error } = await useCustomFetch<IPermission[]>(
      "/permissions/access",
      {}
    );
    if (error.value) {
      ce(
        "usePermissins.fetchPermissions",
        `error: ${JSON.stringify(error.value)}`
      );
    }
    permissions.value = data.value;
  };

  const comparePermission = (
    accessRoles: string[] | undefined,
    userRoles: string[] | undefined,
    title: string
  ): boolean => {
    let result = false;
    for (let i = 0; i < userRoles?.length; i++) {
      if (accessRoles?.includes(userRoles![i])) {
        result = true;
      }
    }
    // cl(
    //   "usePermissions.comparePermission",
    //   `${title}: ${accessRoles} @> ${userRoles} => ${result}`
    // );
    return result;
  };

  const checkPermissions = (
    dashboard: IDashboardItem[],
    userRoles: string[]
  ) => {
    let index = dashboard.length;
    while (index--) {
      const dashboardItem = dashboard[index];

      const accessRoles = permissions.value!.find(
        (item: IPermission) => item.subject === dashboardItem.value
      )?.roles;
      //   console.log(
      //     `${dashboardItem.title}, access: ${accessRoles}, user: ${userRoles}`
      //   );
      if (!comparePermission(accessRoles, userRoles, dashboardItem.title)) {
        dashboard.splice(index, 1);
      }
    }

    if (dashboard.length === 0) {
      isAppDrawerVisible.value = false;
    } else {
      isAppDrawerVisible.value = true;
    }

    // console.log("isAppDrawerVisible", isAppDrawerVisible.value);

    return [...dashboard];
  };

  const check = async () => {
    await fetchPermissions();
    // cl(
    //   "usePermissions.check",
    //   `dashboard #1: ${JSON.stringify(dashboardList)}`
    // );
    dashboard.value = checkPermissions(
      [...dashboardList],
      sessionData.value?.roles as string[]
    );
    // cl(
    //   "usePermissions.check",
    //   `dashboard #2: ${JSON.stringify(dashboard.value)}`
    // );
  };
  return { permissions, dashboard, check, fetchPermissions };
};
